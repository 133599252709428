import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData, title } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData || [];
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        {title}
      </h2>
      <PropertyGroup
        id={title}
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      />
    </div>
  );
};

export default SectionFeaturesMaybe;
